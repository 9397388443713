import I18N from "@/i18n";
import isIBANValidator from "validator/lib/isIBAN";
import { InternationalPhoneRegex } from "./international-phone.regex";

export const isEstablishmentNameValid = (v: string) =>
  !/^(\[\[){1}.+(\]\]){1}$/.test(v);

export const isTagValid = (v: string) => /^[a-z0-9][a-z0-9-_]*$/.test(v);

export const formRules = {
  required: (v: string) => !!v || I18N.t("rules.required"),
  notEmpty: (v?: string | number | null) => {
    if (v !== undefined && v !== "" && v !== null) {
      return true;
    } else {
      return I18N.t("rules.required");
    }
  },
  email: (v: string) => {
    if (!v) {
      return true;
    } else {
      return (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || I18N.t("rules.email")
      );
    }
  },
  emailCommaSeparated: (v: string) => {
    if (!v) {
      return true;
    } else {
      const emails = v.split(",");
      if (emails && emails.length > 0) {
        return (
          emails.every((email) => formRules.email(email) === true && email) ||
          I18N.t("rules.emailCommaSeparated")
        );
      } else {
        return I18N.t("rules.emailCommaSeparated");
      }
    }
  },
  belgianEstablishmentNumber: (v: string) => {
    if (!v) {
      return true;
    } else {
      return (
        /[2-8]\.\d{3}\.\d{3}\.\d{3}/.test(v) ||
        I18N.t("rules.belgianEstablishmentNumber")
      );
    }
  },
  belgianCbeNumber: (v: string) => {
    if (!v) {
      return true;
    } else {
      return (
        /^(\d{4}\.\d{3}\.\d{3})$|^(\d{10})$/.test(v) ||
        I18N.t("rules.belgianCbeNumber")
      );
    }
  },
  passwordMin: (v: string) =>
    (v.length >= 8 && v.length < 32) || I18N.t("rules.passwordLength"),
  postalCode: (v: string) =>
    (Number.parseInt(v) >= 1000 && Number.parseInt(v) <= 9999) ||
    I18N.t("rules.postalCode"),
  date: (v: string) => {
    if (!v) {
      return true;
    } else {
      return !isNaN(new Date(v).getTime()) || I18N.t("rules.date");
    }
  },
  iban: (v: string) => {
    if (!v) {
      return true;
    } else {
      return isIBANValidator(v) || I18N.t("rules.iban");
    }
  },
  onlyAlphaNum: (v: string) =>
    /^[a-zA-Z0-9]*$/.test(v) || I18N.t("rules.onlyAlphaNum"),
  alphaNumWithouSpace: (v: string) =>
    /^[a-zA-Z][a-zA-Z0-9-_]*$/.test(v) || I18N.t("rules.alphaNumWithouSpace"),
  tags: (v: string[]) => {
    if (!v || v.length === 0) {
      return true;
    } else {
      return (
        v.every((tag) => /^[a-z0-9][a-z0-9-_]*$/.test(tag)) ||
        I18N.t("rules.lowerAlphaNumWithouSpace")
      );
    }
  },
  number: (v: string) => {
    if (v === undefined || v === "") {
      return true;
    } else {
      return Number.parseInt(v) > 0 || I18N.t("rules.number");
    }
  },
  numberPositive: (v: string) => {
    if (!v) {
      return true;
    } else {
      return Number.parseInt(v) > 0 || I18N.t("rules.numberPositive");
    }
  },
  numberPositiveOrZero: (v: string) => {
    if (v === undefined || v === null || v === "") {
      return true;
    } else {
      return Number.parseInt(v) >= 0 || I18N.t("rules.numberPositiveOrZero");
    }
  },
  internationalPhone: (v: string) => {
    if (!v) {
      return true;
    } else {
      return (
        InternationalPhoneRegex.test(v) || I18N.t("rules.internationalPhone")
      );
    }
  },
  fileName: (v: string) =>
    /^[A-Za-z0-9-_.,\s]+$/.test(v) || I18N.t("rules.fileName"),
  establishmentNameBrackets: (v: string) =>
    isEstablishmentNameValid(v) || I18N.t("rules.establishmentNameBrackets"),
  zeroToHundred: (v: string) => {
    if (!v) {
      return true;
    } else {
      const n = Number.parseFloat(v);
      return (n >= 0 && n <= 100) || I18N.t("rules.zeroToHundred");
    }
  },
  serialNumberKiosk: (v: string) => {
    if (v === undefined || v === null || v === "") {
      return true;
    } else {
      return (
        /^K[1-9][0-9]*$/.test(v.toUpperCase()) ||
        I18N.t("rules.serialNumberKiosk")
      );
    }
  },
  serialNumberComputer: (v: string) => {
    if (v === undefined || v === null || v === "") {
      return true;
    } else {
      return (
        /^PC[1-9][0-9]*$/.test(v.toUpperCase()) ||
        I18N.t("rules.serialNumberComputer")
      );
    }
  },
  operatorList: (v: string) => {
    if (!v) {
      return true;
    } else {
      const operators = v.split(";");
      if (operators && operators.length > 0) {
        return (
          operators.every((operator) => operator && operator.length <= 64) ||
          I18N.t("rules.operatorLength")
        );
      } else {
        return I18N.t("rules.operatorListSemicolonSeparated");
      }
    }
  },
  ticketCategory: (v: string) => {
    return (
      (v && /^Ticket[a-zA-Z]+$/.test(v)) ||
      I18N.t("rules.ticketAssignmentCategory")
    );
  },
};
