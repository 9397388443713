import { Lang } from "@/utils/lang.enum";
import { HttpMethod } from "../http-method.interface";
import { plainToInstance } from "class-transformer";
import { CallCenterTicketQueryOrderDTO } from "../models/call-center/call-center-ticket-query-order.dto";
import { CallCenterQueryPaginatedDTO } from "../models/call-center/call-center-query-paginated.dto";
import { Win2000TicketPaginatedOutDTO } from "../models/players2000/win-2000-ticket-paginated.out.dto";

export class Win2000Service {
  constructor(private methods: HttpMethod) {}

  async downloadEmptyIdentificationForm(lang: Lang) {
    return await this.methods.getRaw(`/win2000/identificationForm/${lang}`);
  }

  async downloadCompletedIdentificationForm(lang: Lang, ticketId: string) {
    return await this.methods.getRaw(
      `/win2000/identificationForm/${lang}/${ticketId}`
    );
  }

  async export() {
    return await this.methods.getRaw(`/win2000/export`);
  }

  async list(
    term: string,
    offset?: number,
    limit?: number,
    order?: CallCenterTicketQueryOrderDTO
  ) {
    const params = plainToInstance(CallCenterQueryPaginatedDTO, {
      offset,
      limit,
      order: order ? btoa(JSON.stringify(order)) : undefined,
      term,
    });
    return plainToInstance(
      Win2000TicketPaginatedOutDTO,
      await this.methods.get<Win2000TicketPaginatedOutDTO>("/win2000/list", {
        params,
      })
    );
  }
}
