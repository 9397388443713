import GlobalCommentActivator from "@/global-components/comment/GlobalCommentActivator.vue";
import GlobalSMSActivator from "@/global-components/sms/GlobalSMSActivator.vue";
import AuthenticateLayout from "@/layouts/AuthenticateLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import "flag-icons/sass/flag-icons.scss";
import "reflect-metadata";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import { NavigationGuardNext, Route } from "vue-router";
import VueSignaturePad from "vue-signature-pad";
import x5GMaps from "x5-gmaps";
import DatetimePicker from "vuetify-datetime-picker";
import api from "./api/api";
import App from "./App.vue";
import i18n from "./i18n";
import notification from "./notification/notification";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { translateError } from "./utils/translate-error-code";

Vue.config.productionTip = false;

// Register layouts globally
Vue.component("DefaultLayout", DefaultLayout);
Vue.component("AuthenticateLayout", AuthenticateLayout);

// Global components
Vue.component("GlobalCommentActivator", GlobalCommentActivator);
Vue.component("GlobalSMSActivator", GlobalSMSActivator);

api.setCallback(
  (code) => {
    notification.instance.error(translateError(code));
  },
  () => {
    if (router.currentRoute.name !== "Login") {
      router.replace({ name: "Login" });
      notification.instance.info(i18n.t("info.reconnectionNeeded").toString());
    }
  },
  (token) => {
    if (store.getters["user/rememberMe"]) {
      store.commit("user/storeRefreshToken", token);
    }
  },
  (code) => {
    router.replace({ name: "Login" }); // Ejecté
    notification.instance.error(translateError(code));
  },
  () => {
    return store.getters["user/storedRefreshToken"];
  }
);

let endPoint: Route | undefined;
router.beforeEach(
  async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.meta?.noAuthorizationRequired !== true) {
      if (!store.state.user.user) {
        try {
          const user = await api.instance.user.me();
          store.commit("user/setUser", user);
          if (user.clients && user.clients.length > 0) {
            store.commit("user/setSelectedClient", user.clients[0]);
          }
          i18n.locale = user.lang;

          if (endPoint !== undefined) {
            if (to.fullPath !== endPoint.fullPath) {
              router.push(endPoint.fullPath).catch();
              endPoint = undefined;
              return;
            }
          }

          next();
        } catch (e) {
          if (api.isAxiosError(e) && e.response?.status === 401) {
            endPoint = to;
          }
        }
      } else {
        next();
      }
    } else {
      next();
    }
  }
);

Vue.use(api);
Vue.use(notification);
Vue.use(VueClipboard);
Vue.use(x5GMaps, {
  key: process.env.VUE_APP_GOOGLE_API,
  libraries: ["places"],
});
Vue.use(DatetimePicker);
Vue.use(VueSignaturePad);

/**
 * SENTRY
 */
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_SENTRY_ORIGIN, /^\//],
    }),
  ],
  tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE,
  debug: process.env.VUE_APP_SENTRY_DEBUG,
  environment: process.env.NODE_ENV,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
