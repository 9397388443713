
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import { StoreGetter } from "@/store/storeGetter.decorator";
import Vue from "vue";
import Component from "vue-class-component";
import { ACLRight } from "@/api/acl-right.enum";
import MyClientSwitch from "./MyClientSwitch.vue";

const SiteMenuProps = Vue.extend({
  props: {
    showDrawer: {
      type: Boolean,
      default: true,
    },
  },
});

interface MenuItem {
  icon: string;
  title: string;
  routeName: string;
  right?: ACLRight | ACLRight[];
}

@Component({
  components: {
    MyClientSwitch,
  },
})
export default class SiteMenu extends SiteMenuProps {
  @StoreGetter("user/user") user!: UserWithRightOutDTO;

  public get menuItems(): MenuItem[] {
    const items = [
      {
        icon: "mdi-home",
        title: this.$tc("menu.home"),
        routeName: "Home",
      },
      {
        icon: "mdi-account-group",
        title: this.$tc("menu.clients"),
        routeName: "Clients",
        right: ACLRight.CLIENT_LIST,
      },
      {
        icon: "mdi-store",
        title: this.$tc("menu.shops"),
        routeName: "Shops",
        right: ACLRight.SHOP_LIST,
      },
      {
        icon: "mdi-map",
        title: this.$tc("menu.map"),
        routeName: "ShopsMap",
        right: ACLRight.SHOP_LIST,
      },
      {
        icon: "mdi-domain",
        title: this.$tc("menu.enterprises"),
        routeName: "Enterprises",
        right: ACLRight.ENTERPRISE_LIST,
      },
      {
        icon: "mdi-contacts",
        title: this.$tc("menu.persons"),
        routeName: "Persons",
        right: ACLRight.PERSON_LIST,
      },
      {
        icon: "mdi-wrench-clock-outline",
        title: this.$tc("menu.interventions"),
        routeName: "Interventions",
        right: this.user.hasRight(ACLRight.INSTALLATION_ADMIN_LIST_INTERVENTION)
          ? ACLRight.INSTALLATION_ADMIN_LIST_INTERVENTION
          : ACLRight.INSTALLATION_ADMIN_LIST_MY_INTERVENTION,
      },
    ];

    // Call-center
    if (!this.user.hasRight(ACLRight.CALL_CENTER_LIST)) {
      items.push({
        icon: "mdi-face-agent",
        title: this.$tc("menu.callCenter"),
        routeName: "CallCenterTicketCreate",
      });
    } else {
      items.push({
        icon: "mdi-face-agent",
        title: this.$tc("menu.callCenter"),
        routeName: "CallCenterTickets",
        right: this.user.hasRight(ACLRight.CALL_CENTER_LIST)
          ? ACLRight.CALL_CENTER_LIST
          : ACLRight.CALL_CENTER_LIST_MY_TICKET,
      });
    }

    return items;
  }

  public get adminItems(): MenuItem[] {
    return [
      {
        icon: "mdi-badge-account-outline",
        title: this.$tc("menu.users"),
        routeName: "Users",
        right: ACLRight.USER_LIST,
      },
      {
        icon: "mdi-account-key",
        title: this.$tc("menu.roles"),
        routeName: "Roles",
        right: [ACLRight.ROLE_LIST, ACLRight.ACL_LIST],
      },
      {
        icon: "mdi-file-document-edit-outline",
        title: this.$tc("menu.templates"),
        routeName: "Templates",
        right: ACLRight.CONTRACT_TEMPLATE_LIST,
      },
      {
        icon: "mdi-chart-box-outline",
        title: this.$tc("menu.statistics"),
        routeName: "DailyStatistics",
        right: [ACLRight.EXTERNAL_FINANCIAL_DATA_GET_DAILY_STATISTICS],
      },
      {
        icon: "mdi-cash",
        title: this.$tc("menu.agencyManagerPayment"),
        routeName: "AgencyManagerPayment",
        right: [ACLRight.EXTERNAL_FINANCIAL_DATA_GET_STATISTICS_FOR_ALL_CLIENT],
      },
      {
        icon: "mdi-medal",
        title: this.$tc("menu.players1000"),
        routeName: "Players1000",
        right: [ACLRight.PLAYERS_1000],
      },
      {
        icon: "mdi-cash-register",
        title: this.$tc("menu.players2000"),
        routeName: "Players2000",
        right: [ACLRight.CALL_CENTER_LIST],
      },
      {
        icon: "mdi-cog-outline",
        title: this.$tc("menu.settings"),
        routeName: "Settings",
        right: [ACLRight.DOCUSIGN_HAS_CONSENT],
      },
    ];
  }

  public get personalViewItems(): MenuItem[] {
    return [
      {
        icon: "mdi-store",
        title: this.$tc("menu.myShop"),
        routeName: "MyShop",
      },
      {
        icon: "mdi-ticket-outline",
        title: this.$tc("menu.myTickets"),
        routeName: "MyCallCenterTicket",
      },
    ];
  }

  public get grantedAdminItems() {
    return this.adminItems.filter((item) => this.user.hasRight(item.right));
  }

  public get grantedMenuItems() {
    return this.menuItems.filter((item) => this.user.hasRight(item.right));
  }
}
