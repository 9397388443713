import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import _Vue from "vue";
import { ErrorCode } from "./error-code.enum";
import { ACLService } from "./services/acl.service";
import { CallCenterService } from "./services/call-center.service";
import { ClientService } from "./services/client.service";
import { CommentService } from "./services/comment.service";
import { ContractService } from "./services/contract.service";
import { DocusignService } from "./services/docusign.service";
import { EnterpriseService } from "./services/enterprise.service";
import { GamblingCommitteeService } from "./services/gambling-committee.service";
import { InstallationService } from "./services/installation.service";
import { PersonService } from "./services/person.service";
import { PostalCodeService } from "./services/postal-code.service";
import { ExternalFinancialDataService } from "./services/external-financial-data.service";
import { ShopService } from "./services/shop.service";
import { StorageService } from "./services/storage.service";
import { TagService } from "./services/tag.service";
import { UserAuthService } from "./services/user-auth.service";
import { UserService } from "./services/user.service";
import { CallCenterUtilService } from "./utils/call-center-util.service";
import { ClientUtilService } from "./utils/client-util.service";
import { EnterpriseUtilService } from "./utils/enterprise-util.service";
import { PersonUtilService } from "./utils/person-util.service";
import { ShopUtilService } from "./utils/shop-util.service";
import { FinancialService } from "./services/financial.service";
import { InterventionService } from "./services/intervention.service";
import { GlobalStorageService } from "./services/global-storage.service";
import { SMSService } from "./services/sms.service";
import { MailService } from "./services/mail.service";
import { Players1000Service } from "./services/players1000.service";
import { Win2000Service } from "./services/win2000.service";

class ErrorInvalidAccessToken extends Error {}

class Api {
  private static _instance = new Api();

  private static errorCallback?: (errorCode: ErrorCode) => void;
  private static refreshErrorCallback?: () => void;
  private static changeRefreshTokenCallback?: (token?: string) => void;
  private static forbiddenCallback?: (errorCode: ErrorCode) => void;

  public static isAxiosError(error: unknown): error is AxiosError {
    return (
      error !== null &&
      typeof error === "object" &&
      (error as AxiosError).isAxiosError === true
    );
  }

  public static install(vue: typeof _Vue) {
    vue.prototype.$api = Api._instance;
  }

  public static setCallback(
    errorCallback?: (errorCode: ErrorCode) => void,
    refreshErrorCallback?: () => void,
    changeRefreshTokenCallback?: (token?: string) => void,
    forbiddenCallback?: (errorCode: ErrorCode) => void,
    restoreRefreshToken?: () => string | undefined
  ) {
    Api.errorCallback = errorCallback;
    Api.refreshErrorCallback = refreshErrorCallback;
    Api.changeRefreshTokenCallback = changeRefreshTokenCallback;
    Api.forbiddenCallback = forbiddenCallback;
    const refreshToken = restoreRefreshToken
      ? restoreRefreshToken()
      : undefined;
    if (refreshToken !== undefined) {
      Api._instance._refreshToken = refreshToken;
    }
  }

  public static get instance() {
    return Api._instance;
  }

  /**
   * Services
   */
  public auth = new UserAuthService(this.getHttpMethods(), {
    getRefreshToken: this.getRefreshToken.bind(this),
    setAccessToken: this.setAccessToken.bind(this),
    setRefreshToken: this.setRefreshToken.bind(this),
  });
  public user = new UserService(this.getHttpMethods());
  public acl = new ACLService(this.getHttpMethods());
  public shop = new ShopService(this.getHttpMethods());
  public postalCode = new PostalCodeService(this.getHttpMethods());
  public storage = new StorageService(this.getHttpMethods());
  public enterprise = new EnterpriseService(this.getHttpMethods());
  public person = new PersonService(this.getHttpMethods());
  public client = new ClientService(this.getHttpMethods());
  public contract = new ContractService(this.getHttpMethods());
  public tag = new TagService(this.getHttpMethods());
  public comment = new CommentService(this.getHttpMethods());
  public docusign = new DocusignService(this.getHttpMethods());
  public installation = new InstallationService(this.getHttpMethods());
  public callCenter = new CallCenterService(this.getHttpMethods());
  public players1000 = new Players1000Service(this.getHttpMethods());
  public win2000 = new Win2000Service(this.getHttpMethods());
  public externalFinancialData = new ExternalFinancialDataService(
    this.getHttpMethods()
  );
  public gamblingCommittee = new GamblingCommitteeService(
    this.getHttpMethods()
  );
  public financial = new FinancialService(this.getHttpMethods());
  public intervention = new InterventionService(this.getHttpMethods());
  public globalStorage = new GlobalStorageService(this.getHttpMethods());
  public SMS = new SMSService(this.getHttpMethods());
  public mail = new MailService(this.getHttpMethods());

  public enterpriseUtil = new EnterpriseUtilService(this.getHttpMethods());
  public shopUtil = new ShopUtilService(this.getHttpMethods());
  public personUtil = new PersonUtilService(this.getHttpMethods());
  public clientUtil = new ClientUtilService(this.getHttpMethods());
  public callCenterUtil = new CallCenterUtilService(this.getHttpMethods());

  constructor() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = this.getAccessToken();
        if (token && config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        const status = error?.response?.status;
        const responseType = error?.config?.responseType;

        let code;
        if (responseType === "arraybuffer") {
          try {
            const data = JSON.parse(
              String.fromCharCode.apply(
                null,
                new Uint8Array(error?.response?.data) as unknown as number[]
              )
            );
            code = data?.code;
          } catch (e) {
            //
          }
        } else {
          code = error?.response?.data?.code;
        }

        if (status === 401 && code === ErrorCode.AUTH_ACCESS_TOKEN_INVALID) {
          return Promise.reject(new ErrorInvalidAccessToken());
        } else if (
          status === 401 &&
          code === ErrorCode.AUTH_REFRESH_TOKEN_INVALID &&
          Api.refreshErrorCallback
        ) {
          Api.refreshErrorCallback();
        } else if (status === 403 && Api.forbiddenCallback) {
          Api.forbiddenCallback(code ?? ErrorCode.UNKNOWN);
        } else if (Api.errorCallback) {
          Api.errorCallback(code ?? ErrorCode.UNKNOWN);
        }

        return Promise.reject(error);
      }
    );
  }

  private _accessToken?: string;
  private _refreshToken?: string;

  private axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  private setAccessToken(accessToken: string | undefined) {
    this._accessToken = accessToken;
  }

  private getAccessToken() {
    return this._accessToken;
  }

  private setRefreshToken(refreshToken: string | undefined) {
    this._refreshToken = refreshToken;
    if (Api.changeRefreshTokenCallback) {
      Api.changeRefreshTokenCallback(refreshToken);
    }
  }

  private getRefreshToken() {
    return this._refreshToken;
  }

  private async get<T>(
    url: string,
    config?: AxiosRequestConfig<never> | undefined,
    callNumber = 0
  ): Promise<T> {
    try {
      const req = await this.axiosInstance.get(url, config);
      return req.data;
    } catch (e: unknown) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return await this.get(url, config, 1);
      }
      throw e;
    }
  }

  private async getRaw(
    url: string,
    config?: AxiosRequestConfig<never> | undefined,
    callNumber = 0
  ): Promise<ArrayBuffer> {
    try {
      const array = await this.axiosInstance.get(url, {
        ...config,
        responseType: "arraybuffer",
      });
      return array.data as unknown as ArrayBuffer;
    } catch (e: unknown) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return this.getRaw(url, config, 1);
      }
      throw e;
    }
  }

  private async post<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<never> | undefined,
    callNumber = 0
  ): Promise<T> {
    try {
      const req = await this.axiosInstance.post(url, data, config);
      return req.data;
    } catch (e) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return this.post(url, data, config, 1);
      }
      throw e;
    }
  }

  private async postRaw(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<never> | undefined,
    callNumber = 0
  ): Promise<ArrayBuffer> {
    try {
      const array = await this.axiosInstance.post(url, data, {
        ...config,
        responseType: "arraybuffer",
      });
      return array.data as unknown as ArrayBuffer;
    } catch (e: unknown) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return this.postRaw(url, data, config, 1);
      }
      throw e;
    }
  }

  private async patch<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<never> | undefined,
    callNumber = 0
  ): Promise<T> {
    try {
      const req = await this.axiosInstance.patch(url, data, config);
      return req.data;
    } catch (e) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return this.patch(url, data, config, 1);
      }
      throw e;
    }
  }

  private async put<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig<never> | undefined,
    callNumber = 0
  ): Promise<T> {
    try {
      const req = await this.axiosInstance.put(url, data, config);
      return req.data;
    } catch (e) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return this.put(url, data, config, 1);
      }
      throw e;
    }
  }

  private async delete<T>(
    url: string,
    config?: AxiosRequestConfig<never> | undefined,
    callNumber = 0
  ): Promise<T> {
    try {
      const req = await this.axiosInstance.delete(url, config);
      return req.data;
    } catch (e) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return this.delete(url, config, 1);
      }
      throw e;
    }
  }

  private async postFile<T>(
    url: string,
    data: Record<
      string,
      | string
      | string[]
      | number
      | number[]
      | boolean
      | File
      | File[]
      | undefined
    >,
    callNumber = 0
  ): Promise<T> {
    try {
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];
        if (Array.isArray(value)) {
          for (const v of value) {
            if (v instanceof File) {
              formData.append(key, v, v.name);
            } else {
              formData.append(key, v.toString());
            }
          }
        } else if (value !== undefined) {
          if (value instanceof File) {
            formData.append(key, value, value.name);
          } else {
            formData.append(key, value.toString());
          }
        }
      }
      const req = await this.axiosInstance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return req.data;
    } catch (e) {
      if (e instanceof ErrorInvalidAccessToken && callNumber === 0) {
        await this.auth.refresh();
        return this.postFile(url, data, 1);
      }
      throw e;
    }
  }

  private getHttpMethods() {
    return {
      get: this.get.bind(this),
      post: this.post.bind(this),
      delete: this.delete.bind(this),
      patch: this.patch.bind(this),
      put: this.put.bind(this),
      getRaw: this.getRaw.bind(this),
      postFile: this.postFile.bind(this),
      postRaw: this.postRaw.bind(this),
    };
  }
}

export default Api;
export type apiType = Api;
