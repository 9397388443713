import { Type } from "class-transformer";
import { TicketFieldType } from "../type/ticket-field-type.enum";
import { TicketFieldSelectOutDTO } from "./ticket-field-select.out.dto";
import { TicketTextI18NOutDTO } from "./ticket-text-i18n.out.dto";

export class TicketFieldOutDTO {
  @Type(() => TicketTextI18NOutDTO)
  label!: TicketTextI18NOutDTO;

  name!: string;
  required?: boolean;
  locked?: boolean;
  type!: TicketFieldType;
  image?: string;

  @Type(() => TicketFieldSelectOutDTO)
  choices?: TicketFieldSelectOutDTO[];
}
