import { Type } from "class-transformer";
import { ClientSummaryOutDTO } from "../client/client-summary.out.dto";
import { TicketStatus } from "../call-center/type/ticket-status.enum";

export class Win2000TicketSummaryListOutDTO {
  id!: string;
  ref!: number;

  @Type(() => Date)
  changedAt!: Date;

  objectId?: string;
  type!: string;

  status!: TicketStatus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data!: any;
  priority!: number;

  @Type(() => ClientSummaryOutDTO)
  client?: ClientSummaryOutDTO;
}
