import { Lang } from "@/utils/lang.enum";
import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { ClientHistoryOutDTO } from "../models/client/client-history.out.dto";
import { ClientMailingListOutDTO } from "../models/client/client-mailing-list.out.dto";
import { ClientPaginatedOutDTO } from "../models/client/client-paginated.out.dto";
import { ClientQueryFilterDTO } from "../models/client/client-query-filter.dto";
import { ClientQueryOrderDTO } from "../models/client/client-query-order.dto";
import { ClientQueryPaginatedDTO } from "../models/client/client-query-paginated.dto";
import { ClientOutDTO } from "../models/client/client.out.dto";
import { CommissionLevelDTO } from "../models/client/commission-level.dto";
import { ClientSituation } from "../models/client/type/client-situation.enum";
import { ClientStatus } from "../models/client/type/client-status.enum";
import { ClientType } from "../models/client/type/client-type.enum";
import { CommissionBasedOn } from "../models/client/type/commission-based-on.enum";
import { RacingCommissionBasedOn } from "../models/client/type/racing-commission-based-on.enum";
import { ContractType } from "../models/contract/contract-type.enum";
import { TemplatePartsOutDTO } from "../models/contract/template.out.dto";
import { DocumentOutDTO } from "../models/document/document.out.dto";
import { DocumentAction } from "../models/document/type/document-action.enum";
import { ClientFinancialOutDTO } from "../models/financial/client-financial.out.dto";
import { readFileToB64 } from "../utils/read-file-utile.service";
import { LicenceStatus } from "../models/client/type/licence-status.enum";
import { LicenceFinancing } from "../models/client/type/licence-financing.type";
import { LicenceActor } from "../models/client/type/licence-actor.enum";
import { ClientPeriodicity } from "../models/client/type/client-periodicity.enum";

export class ClientService {
  constructor(private methods: HttpMethod) {}

  @memoize(10000, "ClientService")
  async getDetail(id: string) {
    const result = await this.methods.get<ClientOutDTO>(`/client/${id}`);
    return plainToInstance(ClientOutDTO, result);
  }

  @memoize(10000, "ClientService")
  async list(
    search?: string,
    offset?: number,
    limit?: number,
    filter?: ClientQueryFilterDTO,
    order?: ClientQueryOrderDTO
  ) {
    const params = plainToInstance(ClientQueryPaginatedDTO, {
      offset,
      limit,
      search,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<ClientPaginatedOutDTO>(`/client`, {
      params,
    });
    return plainToInstance(ClientPaginatedOutDTO, result);
  }

  @memoize(10000, "ClientService")
  async search(
    search: string,
    offset?: number,
    limit?: number,
    filter?: ClientQueryFilterDTO,
    order?: ClientQueryOrderDTO
  ) {
    const params = plainToInstance(ClientQueryPaginatedDTO, {
      offset,
      limit,
      search,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<ClientPaginatedOutDTO>(
      `/client/search`,
      {
        params,
      }
    );
    return plainToInstance(ClientPaginatedOutDTO, result);
  }

  @memoize(10000, "ClientService")
  async listMailing(search?: string, filter?: ClientQueryFilterDTO) {
    const params = plainToInstance(ClientQueryPaginatedDTO, {
      search,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
    });
    const result = await this.methods.get<ClientMailingListOutDTO[]>(
      `/client/mailing`,
      {
        params,
      }
    );
    return plainToInstance(ClientMailingListOutDTO, result);
  }

  async createWithTemplate(
    shopId: string,
    enterpriseId: string,
    usersEmails: string[],
    clientType: ClientType,
    clientSituation: ClientSituation[],
    clientContractDurationInMonth: number,
    clientExclusivity: boolean,
    periodicity: ClientPeriodicity,
    clientFeesAndTaxesRatio: number,
    clientWeekNegativeReport: number,
    contractType: ContractType,
    contractStatus: string,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>,
    managedClient: boolean,
    licenceAtPartner: boolean,
    gamblingCommitteeLicenceRequester: LicenceActor,
    gamblingCommitteeLicencePayer: LicenceActor,
    gamblingCommitteeLicencePrice?: number,
    gamblingCommitteeDocumentIds?: string[],
    gamblingCommitteeLicenceFinancing?: LicenceFinancing,
    gamblingCommitteeLicenceRequesterOtherOperator?: string,
    gamblingCommitteeLicencePayerOtherOperator?: string,
    gamblingCommitteeLicenceNumber?: string,
    gamblingCommitteeRequestLicenceDate?: string,
    gamblingCommitteeLicenceStartDate?: string,
    gamblingCommitteeLicencePaymentDate?: string,
    gamblingCommitteeLicenceExpirationDate?: string,
    gamblingCommitteeLicenceStatus?: LicenceStatus,
    gamblingCommitteeExtraInfos?: Record<string, unknown>,
    additionalOperators?: string[],
    commissionType?: CommissionBasedOn | null,
    commissionLevels?: CommissionLevelDTO[],
    commissionRatio?: number,
    greyhoundsType?: RacingCommissionBasedOn | null,
    greyhoundsRatio?: number,
    horseRacingType?: RacingCommissionBasedOn | null,
    horseRacingRatio?: number,
    cashCardCommissionFeesRatio?: number,
    cashCardCommissionWeeklyRatio?: number,
    validFrom?: string,
    validUntil?: string,
    vincennesId?: string,
    stereoluxId1?: string,
    stereoluxId2?: string,
    distributor?: string,
    brand?: string,
    sfkContractNumber?: string
  ) {
    clearBucketMemoization("ClientService");
    const result = await this.methods.post<ClientOutDTO>(`/client`, {
      shopId,
      enterpriseId,
      type: clientType,
      situation: clientSituation,
      contractDurationInMonth: clientContractDurationInMonth,
      exclusivity: clientExclusivity,
      periodicity,
      feesAndTaxesRatio: clientFeesAndTaxesRatio,
      weekNegativeReport: clientWeekNegativeReport,
      vincennesId,
      stereoluxId1,
      stereoluxId2,
      distributor,
      brand,
      sfkContractNumber,
      managedClient,
      licenceAtPartner,
      gamblingCommittee: gamblingCommitteeLicencePrice
        ? {
            licencePrice: gamblingCommitteeLicencePrice,
            documentIds: gamblingCommitteeDocumentIds,
            licenceRequester: gamblingCommitteeLicenceRequester,
            licencePayer: gamblingCommitteeLicencePayer,
            licenceFinancing: gamblingCommitteeLicenceFinancing,
            licenceRequesterOtherOperator:
              gamblingCommitteeLicenceRequesterOtherOperator,
            licencePayerOtherOperator:
              gamblingCommitteeLicencePayerOtherOperator,
            licenceNumber: gamblingCommitteeLicenceNumber,
            requestLicenceDate: gamblingCommitteeRequestLicenceDate,
            licenceStartDate: gamblingCommitteeLicenceStartDate,
            licencePaymentDate: gamblingCommitteeLicencePaymentDate,
            licenceExpirationDate: gamblingCommitteeLicenceExpirationDate,
            licenceStatus: gamblingCommitteeLicenceStatus,
            extraInfos: gamblingCommitteeExtraInfos,
          }
        : undefined,
      additionalOperators,
      usersEmails,
      newContract: {
        type: contractType,
        status: contractStatus,
        template: {
          name: templateName,
          lang,
          customFieldsValue,
        },
        validFrom,
        validUntil,
      },
      commission:
        commissionType !== undefined && commissionType !== null
          ? {
              type: commissionType,
              ratio: commissionRatio,
              levels: commissionLevels,
            }
          : undefined,
      greyhoundsCommission:
        greyhoundsType !== undefined &&
        greyhoundsType !== null &&
        greyhoundsRatio !== undefined
          ? {
              type: greyhoundsType,
              ratio: greyhoundsRatio,
            }
          : undefined,
      horseRacingCommission:
        horseRacingType !== undefined &&
        horseRacingType !== null &&
        horseRacingRatio !== undefined
          ? {
              type: horseRacingType,
              ratio: horseRacingRatio,
            }
          : undefined,
      cashCardCommission:
        cashCardCommissionFeesRatio !== undefined &&
        cashCardCommissionWeeklyRatio !== undefined
          ? {
              feesRatio: cashCardCommissionFeesRatio,
              weeklyRatio: cashCardCommissionWeeklyRatio,
            }
          : undefined,
    });
    return plainToInstance(ClientOutDTO, result);
  }

  async createWithPrefilled(
    shopId: string,
    enterpriseId: string,
    usersEmails: string[],
    clientType: ClientType,
    clientSituation: ClientSituation[],
    clientContractDurationInMonth: number,
    clientExclusivity: boolean,
    periodicity: ClientPeriodicity,
    clientFeesAndTaxesRatio: number,
    clientWeekNegativeReport: number,
    contractType: ContractType,
    contractStatus: string,
    parts: TemplatePartsOutDTO,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>,
    managedClient: boolean,
    licenceAtPartner: boolean,
    gamblingCommitteeLicenceRequester: LicenceActor,
    gamblingCommitteeLicencePayer: LicenceActor,
    gamblingCommitteeLicencePrice?: number,
    gamblingCommitteeDocumentIds?: string[],
    gamblingCommitteeLicenceFinancing?: LicenceFinancing,
    gamblingCommitteeLicenceRequesterOtherOperator?: string,
    gamblingCommitteeLicencePayerOtherOperator?: string,
    gamblingCommitteeLicenceNumber?: string,
    gamblingCommitteeRequestLicenceDate?: string,
    gamblingCommitteeLicenceStartDate?: string,
    gamblingCommitteeLicencePaymentDate?: string,
    gamblingCommitteeLicenceExpirationDate?: string,
    gamblingCommitteeLicenceStatus?: LicenceStatus,
    gamblingCommitteeExtraInfos?: Record<string, unknown>,
    additionalOperators?: string[],
    commissionType?: CommissionBasedOn | null,
    commissionLevels?: CommissionLevelDTO[],
    commissionRatio?: number,
    greyhoundsType?: RacingCommissionBasedOn | null,
    greyhoundsRatio?: number,
    horseRacingType?: RacingCommissionBasedOn | null,
    horseRacingRatio?: number,
    cashCardCommissionFeesRatio?: number,
    cashCardCommissionWeeklyRatio?: number,
    validFrom?: string,
    validUntil?: string,
    vincennesId?: string,
    stereoluxId1?: string,
    stereoluxId2?: string,
    distributor?: string,
    brand?: string,
    sfkContractNumber?: string
  ) {
    clearBucketMemoization("ClientService");
    const result = await this.methods.post<ClientOutDTO>(`/client`, {
      shopId,
      enterpriseId,
      type: clientType,
      situation: clientSituation,
      contractDurationInMonth: clientContractDurationInMonth,
      exclusivity: clientExclusivity,
      periodicity,
      feesAndTaxesRatio: clientFeesAndTaxesRatio,
      weekNegativeReport: clientWeekNegativeReport,
      vincennesId,
      stereoluxId1,
      stereoluxId2,
      distributor,
      brand,
      sfkContractNumber,
      managedClient,
      licenceAtPartner,
      gamblingCommittee: gamblingCommitteeLicencePrice
        ? {
            licencePrice: gamblingCommitteeLicencePrice,
            documentIds: gamblingCommitteeDocumentIds,
            licenceRequester: gamblingCommitteeLicenceRequester,
            licencePayer: gamblingCommitteeLicencePayer,
            licenceFinancing: gamblingCommitteeLicenceFinancing,
            licenceRequesterOtherOperator:
              gamblingCommitteeLicenceRequesterOtherOperator,
            licencePayerOtherOperator:
              gamblingCommitteeLicencePayerOtherOperator,
            licenceNumber: gamblingCommitteeLicenceNumber,
            requestLicenceDate: gamblingCommitteeRequestLicenceDate,
            licenceStartDate: gamblingCommitteeLicenceStartDate,
            licencePaymentDate: gamblingCommitteeLicencePaymentDate,
            licenceExpirationDate: gamblingCommitteeLicenceExpirationDate,
            licenceStatus: gamblingCommitteeLicenceStatus,
            extraInfos: gamblingCommitteeExtraInfos,
          }
        : undefined,
      additionalOperators,
      usersEmails,
      newContract: {
        type: contractType,
        status: contractStatus,
        preFilledTemplate: {
          parts,
          name: templateName,
          lang,
          customFieldsValue,
        },
        validFrom,
        validUntil,
      },
      commission:
        commissionType !== undefined && commissionType !== null
          ? {
              type: commissionType,
              ratio: commissionRatio,
              levels: commissionLevels,
            }
          : undefined,
      greyhoundsCommission:
        greyhoundsType !== undefined &&
        greyhoundsType !== null &&
        greyhoundsRatio !== undefined
          ? {
              type: greyhoundsType,
              ratio: greyhoundsRatio,
            }
          : undefined,
      horseRacingCommission:
        horseRacingType !== undefined &&
        horseRacingType !== null &&
        horseRacingRatio !== undefined
          ? {
              type: horseRacingType,
              ratio: horseRacingRatio,
            }
          : undefined,
      cashCardCommission:
        cashCardCommissionFeesRatio !== undefined &&
        cashCardCommissionWeeklyRatio !== undefined
          ? {
              feesRatio: cashCardCommissionFeesRatio,
              weeklyRatio: cashCardCommissionWeeklyRatio,
            }
          : undefined,
    });
    return plainToInstance(ClientOutDTO, result);
  }

  async createWithFile(
    shopId: string,
    enterpriseId: string,
    usersEmails: string[],
    clientType: ClientType,
    clientSituation: ClientSituation[],
    clientContractDurationInMonth: number,
    clientExclusivity: boolean,
    periodicity: ClientPeriodicity,
    clientFeesAndTaxesRatio: number,
    clientWeekNegativeReport: number,
    contractType: ContractType,
    contractStatus: string,
    fromFile: File,
    managedClient: boolean,
    licenceAtPartner: boolean,
    gamblingCommitteeLicenceRequester: LicenceActor,
    gamblingCommitteeLicencePayer: LicenceActor,
    gamblingCommitteeLicencePrice?: number,
    gamblingCommitteeDocumentIds?: string[],
    gamblingCommitteeLicenceFinancing?: LicenceFinancing,
    gamblingCommitteeLicenceRequesterOtherOperator?: string,
    gamblingCommitteeLicencePayerOtherOperator?: string,
    gamblingCommitteeLicenceNumber?: string,
    gamblingCommitteeRequestLicenceDate?: string,
    gamblingCommitteeLicenceStartDate?: string,
    gamblingCommitteeLicencePaymentDate?: string,
    gamblingCommitteeLicenceExpirationDate?: string,
    gamblingCommitteeLicenceStatus?: LicenceStatus,
    gamblingCommitteeExtraInfos?: Record<string, unknown>,
    additionalOperators?: string[],
    commissionType?: CommissionBasedOn | null,
    commissionLevels?: CommissionLevelDTO[],
    commissionRatio?: number,
    greyhoundsType?: RacingCommissionBasedOn | null,
    greyhoundsRatio?: number,
    horseRacingType?: RacingCommissionBasedOn | null,
    horseRacingRatio?: number,
    cashCardCommissionFeesRatio?: number,
    cashCardCommissionWeeklyRatio?: number,
    validFrom?: string,
    validUntil?: string,
    vincennesId?: string,
    stereoluxId1?: string,
    stereoluxId2?: string,
    distributor?: string,
    brand?: string,
    contractStatusAddendum?: Record<string, unknown>,
    sfkContractNumber?: string
  ) {
    clearBucketMemoization("ClientService");

    const result = await this.methods.post<ClientOutDTO>(`/client`, {
      shopId,
      enterpriseId,
      type: clientType,
      situation: clientSituation,
      contractDurationInMonth: clientContractDurationInMonth,
      exclusivity: clientExclusivity,
      periodicity,
      feesAndTaxesRatio: clientFeesAndTaxesRatio,
      weekNegativeReport: clientWeekNegativeReport,
      vincennesId,
      stereoluxId1,
      stereoluxId2,
      distributor,
      brand,
      sfkContractNumber,
      managedClient,
      licenceAtPartner,
      gamblingCommittee: gamblingCommitteeLicencePrice
        ? {
            licencePrice: gamblingCommitteeLicencePrice,
            documentIds: gamblingCommitteeDocumentIds,
            licenceRequester: gamblingCommitteeLicenceRequester,
            licencePayer: gamblingCommitteeLicencePayer,
            licenceFinancing: gamblingCommitteeLicenceFinancing,
            licenceRequesterOtherOperator:
              gamblingCommitteeLicenceRequesterOtherOperator,
            licencePayerOtherOperator:
              gamblingCommitteeLicencePayerOtherOperator,
            licenceNumber: gamblingCommitteeLicenceNumber,
            requestLicenceDate: gamblingCommitteeRequestLicenceDate,
            licenceStartDate: gamblingCommitteeLicenceStartDate,
            licencePaymentDate: gamblingCommitteeLicencePaymentDate,
            licenceExpirationDate: gamblingCommitteeLicenceExpirationDate,
            licenceStatus: gamblingCommitteeLicenceStatus,
            extraInfos: gamblingCommitteeExtraInfos,
          }
        : undefined,
      additionalOperators,
      usersEmails,
      newContract: {
        type: contractType,
        status: contractStatus,
        statusAddendum: contractStatusAddendum,
        document: {
          documentData: await readFileToB64(fromFile),
          originalFileName: fromFile.name,
        },
        validFrom,
        validUntil,
      },
      commission:
        commissionType !== undefined && commissionType !== null
          ? {
              type: commissionType,
              ratio: commissionRatio,
              levels: commissionLevels,
            }
          : undefined,
      greyhoundsCommission:
        greyhoundsType !== undefined &&
        greyhoundsType !== null &&
        greyhoundsRatio !== undefined
          ? {
              type: greyhoundsType,
              ratio: greyhoundsRatio,
            }
          : undefined,
      horseRacingCommission:
        horseRacingType !== undefined &&
        horseRacingType !== null &&
        horseRacingRatio !== undefined
          ? {
              type: horseRacingType,
              ratio: horseRacingRatio,
            }
          : undefined,
      cashCardCommission:
        cashCardCommissionFeesRatio !== undefined &&
        cashCardCommissionWeeklyRatio !== undefined
          ? {
              feesRatio: cashCardCommissionFeesRatio,
              weeklyRatio: cashCardCommissionWeeklyRatio,
            }
          : undefined,
    });
    return plainToInstance(ClientOutDTO, result);
  }

  async update(
    clientId: string,
    usersEmails: string[],
    type: ClientType,
    situation: ClientSituation[],
    clientContractDurationInMonth?: number,
    clientExclusivity?: boolean,
    periodicity?: ClientPeriodicity,
    clientFeesAndTaxesRatio?: number,
    clientWeekNegativeReport?: number,
    managedClient?: boolean,
    licenceAtPartner?: boolean,
    additionalOperators?: string[],
    commissionType?: CommissionBasedOn | null,
    commissionLevels?: CommissionLevelDTO[],
    commissionRatio?: number,
    greyhoundsType?: RacingCommissionBasedOn | null,
    greyhoundsRatio?: number,
    horseRacingType?: RacingCommissionBasedOn | null,
    horseRacingRatio?: number,
    cashCardCommissionFeesRatio?: number,
    cashCardCommissionWeeklyRatio?: number,
    vincennesId?: string | null,
    stereoluxId1?: string | null,
    stereoluxId2?: string | null,
    distributor?: string | null,
    brand?: string | null,
    sfkContractNumber?: string | null,
    shopId?: string,
    enterpriseId?: string
  ) {
    clearBucketMemoization("ClientService");
    let path = `/client/${clientId}`;
    if (shopId !== undefined || enterpriseId !== undefined) {
      path = `/client/admin/${clientId}`;
    }
    const result = await this.methods.patch<ClientOutDTO>(path, {
      shopId,
      enterpriseId,
      usersEmails,
      type,
      situation,
      contractDurationInMonth: clientContractDurationInMonth,
      exclusivity: clientExclusivity,
      periodicity,
      feesAndTaxesRatio: clientFeesAndTaxesRatio,
      weekNegativeReport: clientWeekNegativeReport,
      vincennesId,
      stereoluxId1,
      stereoluxId2,
      distributor,
      brand,
      sfkContractNumber,
      managedClient,
      licenceAtPartner,
      additionalOperators,
      commission:
        commissionType !== null && commissionType !== undefined
          ? {
              type: commissionType,
              ratio: commissionRatio,
              levels: commissionLevels,
            }
          : null,
      greyhoundsCommission:
        greyhoundsType !== null && greyhoundsType !== undefined
          ? {
              type: greyhoundsType,
              ratio: greyhoundsRatio,
            }
          : null,
      horseRacingCommission:
        horseRacingType !== null && horseRacingType !== undefined
          ? {
              type: horseRacingType,
              ratio: horseRacingRatio,
            }
          : null,
      cashCardCommission:
        cashCardCommissionFeesRatio !== undefined &&
        cashCardCommissionWeeklyRatio !== undefined
          ? {
              feesRatio: cashCardCommissionFeesRatio,
              weeklyRatio: cashCardCommissionWeeklyRatio,
            }
          : null,
    });
    return plainToInstance(ClientOutDTO, result);
  }

  async updateStatus(clientId: string, status: ClientStatus) {
    clearBucketMemoization("ClientService");
    const result = await this.methods.patch<ClientOutDTO>(
      `/client/${clientId}`,
      {
        status,
      }
    );
    return plainToInstance(ClientOutDTO, result);
  }

  async rescind(clientId: string) {
    clearBucketMemoization("ClientService");
    const result = await this.methods.patch<ClientOutDTO>(
      `/client/${clientId}`,
      {
        status: ClientStatus.Rescinded,
      }
    );
    return plainToInstance(ClientOutDTO, result);
  }

  /**
   * DOCUMENTS
   */

  async appendDocument(clientId: string, file: File) {
    clearBucketMemoization("ClientService");
    return await this.methods.postFile(`/client/${clientId}/document`, {
      file,
    });
  }

  async getDocument(clientId: string, documentId: string) {
    return await this.methods.getRaw(
      `/client/${clientId}/document/${documentId}`
    );
  }

  async removeDocument(clientId: string, documentId: string) {
    clearBucketMemoization("ClientService");
    return await this.methods.delete(
      `/client/${clientId}/document/${documentId}`
    );
  }

  async updateDocument(
    clientId: string,
    documentId: string,
    category?: string | null,
    publicDocument?: boolean,
    tags?: string[],
    validFrom?: string,
    validUntil?: string,
    action?: DocumentAction,
    originalFileName?: string
  ) {
    clearBucketMemoization("ClientService");
    const result = await this.methods.patch<DocumentOutDTO>(
      `/client/${clientId}/document/${documentId}`,
      {
        category,
        public: publicDocument,
        tags,
        validFrom,
        validUntil,
        action,
        originalFileName,
      }
    );
    return plainToInstance(DocumentOutDTO, result);
  }

  async export(
    term?: string,
    filter?: ClientQueryFilterDTO,
    order?: ClientQueryOrderDTO,
    includeOnly?: string[],
    exclude?: string[],
    lang?: Lang
  ) {
    return await this.methods.getRaw(`/client/export`, {
      params: {
        term: term ? term : undefined,
        filter: filter ? btoa(JSON.stringify(filter)) : undefined,
        order: order ? btoa(JSON.stringify(order)) : undefined,
        includeOnly,
        exclude,
        lang,
      },
    });
  }

  async getZipDocuments(clientId: string, documentIds: string[]) {
    return await this.methods.postRaw(`/client/${clientId}/zip-documents`, {
      documentIds,
    });
  }

  @memoize(10000, "ClientService")
  async getFinancial(clientId: string) {
    return plainToInstance(
      ClientFinancialOutDTO,
      await this.methods.get<ClientFinancialOutDTO[]>(
        `/client/${clientId}/financial`
      )
    );
  }

  async exportFinancial(clientId: string, lang?: Lang) {
    return await this.methods.getRaw(`/client/export/${clientId}/financial`, {
      params: {
        lang,
      },
    });
  }

  @memoize(10000, "ClientService")
  async listMyClient() {
    return plainToInstance(
      DocumentOutDTO,
      await this.methods.get<ClientOutDTO[]>(`/client/me`)
    );
  }

  @memoize(10000, "ClientService")
  async listMyClientDocument(clientId: string) {
    return plainToInstance(
      DocumentOutDTO,
      await this.methods.get<DocumentOutDTO[]>(
        `/client/me/${clientId}/document`
      )
    );
  }

  async getMyClientDocument(clientId: string, documentId: string) {
    return await this.methods.getRaw(
      `/client/me/${clientId}/document/${documentId}`
    );
  }

  @memoize(10000, "ClientService")
  async getMyFinancial(clientId: string) {
    return plainToInstance(
      ClientFinancialOutDTO,
      await this.methods.get<ClientFinancialOutDTO[]>(
        `/client/me/${clientId}/financial`
      )
    );
  }

  @memoize(10000, "ClientService")
  async getClientHistory(clientId: string) {
    return plainToInstance(
      ClientHistoryOutDTO,
      await this.methods.get<ClientHistoryOutDTO[]>(
        `/client-history/${clientId}`
      )
    );
  }
}
